import { AssetManagerConfig } from '../../asset_manager/config/config';
import { BlockManagerConfig } from '../../block_manager/config/config';
import { CanvasConfig } from '../../canvas/config/config';
import { CodeManagerConfig } from '../../code_manager/config/config';
import { CssComposerConfig } from '../../css_composer/config/config';
import { DeviceManagerConfig } from '../../device_manager/config/config';
import { I18nConfig } from '../../i18n/config';
import { ModalConfig } from '../../modal_dialog/config/config';
import { LayerManagerConfig } from '../../navigator/config/config';
import { PageManagerConfig } from '../../pages';
import { PanelsConfig } from '../../panels/config/config';
import { ParserConfig } from '../../parser/config/config';
import { RichTextEditorConfig } from '../../rich_text_editor/config/config';
import { SelectorManagerConfig } from '../../selector_manager/config/config';
import { StorageManagerConfig } from '../../storage_manager/config/config';
import { UndoManagerConfig } from '../../undo_manager/config';
import { Plugin } from '../../plugin_manager';
import { TraitManagerConfig } from '../../trait_manager/config/config';
import { CommandsConfig } from '../../commands/config/config';
import { StyleManagerConfig } from '../../style_manager/config/config';
import { DomComponentsConfig } from '../../dom_components/config/config';
import { HTMLGeneratorBuildOptions } from '../../code_manager/model/HtmlGenerator';
import { CssGeneratorBuildOptions } from '../../code_manager/model/CssGenerator';
import { ObjectAny } from '../../common';
import gjsForms from 'grapesjs-plugin-forms';

export interface EditorConfig {
  /**
   * Style class name prefix.
   * @default 'gjs-'
   */
  stylePrefix?: string;

  /**
   * Selector which indicates where render the editor.
   */
  container?: string | HTMLElement;

  /**
   * If true, auto-render the content
   * @default true
   */
  autorender?: boolean;

  /**
   * Array of plugins to execute on start.
   * @default []
   */
  plugins?: (string | Plugin<any>)[];

  /**
   * Custom options for plugins
   * @default {}
   */
  pluginsOpts?: Record<string, any>;

  /**
   * Init headless editor.
   * @default false
   */
  headless?: boolean;

  /**
   * Initial project data (JSON containing your components/styles/etc) to load.
   */
  projectData?: ObjectAny;

  /**
   * HTML string or object of components
   * @deprecated Rely on `projectData` option
   * @default ''
   */
  components?: string;

  /**
   * CSS string or object of rules
   * @deprecated Rely on `projectData` option
   * @default ''
   */
  style?: string;

  /**
   * If true, will fetch HTML and CSS from the selected container.
   * @deprecated
   * @default false
   */
  fromElement?: boolean;

  /**
   * Show an alert before unload the page with unsaved changes
   * @default true
   */
  noticeOnUnload?: boolean;

  /**
   * Show paddings and margins.
   * @default false
   */
  showOffsets?: boolean;

  /**
   * Show paddings and margins on selected component
   * @default false
   */
  showOffsetsSelected?: boolean;

  /**
   * On creation of a new Component (via object), if the 'style' attribute is not
   * empty, all those roles will be moved in its new class.
   * @default true
   */
  forceClass?: boolean;

  /**
   * Height for the editor container
   * @default '900px'
   */
  height?: string;

  /**
   * Width for the editor container
   * @default '100%'
   */
  width?: string;

  /**
   * Type of logs to print with the logger (by default is used the devtool console).
   * Available by default: debug, info, warning, error.
   * You can use `false` to disable all of them or `true` to print all of them.
   * @default ['warning', 'error']
   */
  log?: ('debug' | 'info' | 'warning' | 'error')[] | boolean;

  /**
   * By default Grapes injects base CSS into the canvas. For example, it sets body margin to 0
   * and sets a default background color of white. This CSS is desired in most cases.
   * use this property if you wish to overwrite the base CSS to your own CSS. This is most
   * useful if for example your template is not based off a document with 0 as body margin.
   * @deprecated in favor of `config.canvas.frameStyle`
   * @default ''
   */
  baseCss?: string;

  /**
   * CSS that could only be seen (for instance, inside the code viewer)
   * @default '* { box-sizing: border-box; } body {margin: 0;}'
   */
  protectedCss?: string;

  /**
   * CSS for the iframe which containing the canvas, useful if you need to customize
   * something inside (eg. the style of the selected component).
   * @default ''
   */
  canvasCss?: string;

  /**
   * Default command
   * @default 'select-comp'
   */
  defaultCommand?: string;

  /**
   * Show a toolbar when the component is selected
   * @default true
   */
  showToolbar?: boolean;

  // Allow script tag importing
  // @deprecated in favor of `config.parser.optionsHtml.allowScripts`
  // allowScripts: 0,

  /**
   * If true render a select of available devices
   * @default true
   */
  showDevices?: boolean;

  /**
   * When enabled, on device change media rules won't be created
   * @default false
   */
  devicePreviewMode?: boolean;

  /**
   * The condition to use for media queries, eg. 'max-width'.
   * Comes handy for mobile-first cases.
   * @default 'max-width'
   */
  mediaCondition?: string;

  /**
   * Starting tag for variable inside scripts in Components
   * @deprecated Rely on 'script-props' https://grapesjs.com/docs/modules/Components-js.html#passing-properties-to-scripts
   * @default '{[ '
   */
  tagVarStart?: string;

  /**
   * Ending tag for variable inside scripts in Components
   * @deprecated Rely on 'script-props' https://grapesjs.com/docs/modules/Components-js.html#passing-properties-to-scripts
   * @default ' ]}'
   */
  tagVarEnd?: string;

  /**
   * When false, removes empty text nodes when parsed, unless they contain a space.
   * @default false
   */
  keepEmptyTextNodes?: boolean;

  /**
   * Return JS of components inside HTML from 'editor.getHtml()'.
   * @default true
   */
  jsInHtml?: boolean;

  /**
   * Enable native HTML5 drag and drop.
   * @default true
   */
  nativeDnD?: boolean;

  /**
   * Enable multiple component selection.
   * @default true
   */
  multipleSelection?: boolean;

  /**
   * Pass default available options wherever `editor.getHtml()` is called.
   * @default {}
   */
  optsHtml?: HTMLGeneratorBuildOptions;

  /**
   * Pass default available options wherever `editor.getCss()` is called
   * @default {}
   */
  optsCss?: CssGeneratorBuildOptions;

  /**
   * Usually when you update the `style` of the component this changes the
   * element's `style` attribute. Unfortunately, inline styling doesn't allow
   * use of media queries (@media) or even pseudo selectors (eg. :hover).
   * When `avoidInlineStyle` is true all styles are inserted inside the css rule
   * @deprecated Don't use this option, we don't support inline styling anymore.
   */
  avoidInlineStyle?: boolean;

  /**
   * Avoid default properties from storable JSON data, like `components` and `styles`.
   * With this option enabled your data will be smaller (usefull if need to
   * save some storage space).
   * @default true
   */
  avoidDefaults?: boolean;

  /**
   * (experimental)
   * The structure of components is always on the screen but it's not the same
   * for style rules. When you delete a component you might leave a lot of styles
   * which will never be used again, therefore they might be removed.
   * With this option set to true, styles not used from the CSS generator (so in
   * any case where `CssGenerator.build` is used) will be removed automatically.
   * But be careful, not always leaving the style not used mean you wouldn't
   * use it later, but this option comes really handy when deal with big templates.
   * @default false
   */
  clearStyles?: boolean;

  /**
   * Specify the global drag mode of components. By default, components are moved
   * following the HTML flow. Two other options are available:
   * 'absolute' - Move components absolutely (design tools way)
   * 'translate' - Use translate CSS from transform property
   * To get more about this feature read: https://github.com/GrapesJS/grapesjs/issues/1936.
   */
  dragMode?: 'translate' | 'absolute';

  /**
   * When the editor is placed in a scrollable container (eg. modals) this might
   * cause elements inside the canvas (eg. floating toolbars) to be misaligned.
   * To avoid that, you can specify an array of DOM elements on which their scroll will
   * trigger the canvas update.
   * Be default, if the array is empty, the first parent element will be appended.
   * listenToEl: [document.querySelector('#scrollable-el')],
   * @default []
   * */
  listenToEl?: HTMLElement[];

  /**
   * Import asynchronously CSS to use as icons.
   * @default 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
   * */
  cssIcons?: string;

  /**
   * Experimental: don't use.
   * Editor icons
   */
  icons?: ObjectAny;

  /**
   * Configurations for I18n.
   */
  i18n?: I18nConfig;

  /**
   * Configurations for Undo Manager
   */
  undoManager?: UndoManagerConfig | boolean;

  /**
   * Configurations for Asset Manager.
   */
  assetManager?: AssetManagerConfig;

  /**
   * Configurations for Canvas.
   */
  canvas?: CanvasConfig;

  /**
   * Configurations for Storage Manager.
   */
  storageManager?: StorageManagerConfig | boolean;

  /**
   * Configurations for Rich Text Editor.
   */
  richTextEditor?: RichTextEditorConfig;

  /**
   * Configurations for DomComponents
   */
  domComponents?: DomComponentsConfig;

  /**
   * Configurations for Modal Dialog.
   */
  modal?: ModalConfig;

  /**
   * Configurations for Code Manager.
   */
  codeManager?: CodeManagerConfig;

  /**
   * Configurations for Panels.
   */
  panels?: PanelsConfig;

  /**
   * Configurations for Commands.
   */
  commands?: CommandsConfig;

  /**
   * Configurations for Css Composer.
   */
  cssComposer?: CssComposerConfig;

  /**
   * Configurations for Selector Manager.
   */
  selectorManager?: SelectorManagerConfig;

  /**
   * Configurations for Device Manager.
   */
  deviceManager?: DeviceManagerConfig;

  /**
   * Configurations for Style Manager.
   */
  styleManager?: StyleManagerConfig;

  /**
   * Configurations for Block Manager.
   */
  blockManager?: BlockManagerConfig;

  /**
   * Configurations for Trait Manager.
   */
  traitManager?: TraitManagerConfig;

  /**
   * Configurations for Page Manager.
   */
  pageManager?: PageManagerConfig;

  /**
   * Configurations for Layer Manager.
   */
  layerManager?: LayerManagerConfig;

  /**
   * Configurations for Parser module.
   */
  parser?: ParserConfig;

  /** Texts **/
  textViewCode?: string;

  /**
   * Keep unused styles within the editor.
   * @default false
   */
  keepUnusedStyles?: boolean;

  /**
   * Experimental: don't use.
   * Avoid default UI styles.
   */
  customUI?: boolean;
  el?: HTMLElement;
  /**
   * Color picker options.
   */
  colorPicker?: ObjectAny;
  pStylePrefix?: string;
}

export type EditorConfigKeys = keyof EditorConfig;

const svgText = `<svg style="width:48px;height:48px" viewBox="0 0 24 24">
<path fill="currentColor" d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z" />
</svg>`;
const svgLink = `<svg style="width:48px;height:48px" viewBox="0 0 24 24">
<path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
</svg>`;
const svgImage = `<svg style="width:48px;height:48px" viewBox="0 0 24 24">
<path fill="currentColor" d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
</svg>`;
const svgVideo = `<svg style="width:48px;height:48px" viewBox="0 0 24 24">
<path fill="currentColor" d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z"></path>
</svg>`;

const config: EditorConfig = {
  stylePrefix: 'gjs-',
  components: '',
  style: '',
  fromElement: false,
  projectData: undefined,
  noticeOnUnload: true,
  showOffsets: false,
  showOffsetsSelected: false,
  forceClass: true,
  height: '900px',
  width: '100%',
  log: ['warning', 'error'],
  baseCss: '',
  protectedCss: '* { box-sizing: border-box; } body {margin: 0;}',
  canvasCss: '',
  defaultCommand: 'select-comp',
  showToolbar: true,
  showDevices: true,
  devicePreviewMode: false,
  mediaCondition: 'max-width',
  tagVarStart: '{[ ',
  tagVarEnd: ' ]}',
  keepEmptyTextNodes: false,
  jsInHtml: true,
  nativeDnD: true,
  multipleSelection: true,
  optsHtml: {},
  optsCss: {},
  avoidInlineStyle: true,
  avoidDefaults: true,
  clearStyles: false,
  listenToEl: [],
  cssIcons: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css',
  icons: {
    close:
      '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path></svg>',
    move: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M13,6V11H18V7.75L22.25,12L18,16.25V13H13V18H16.25L12,22.25L7.75,18H11V13H6V16.25L1.75,12L6,7.75V11H11V6H7.75L12,1.75L16.25,6H13Z"/></svg>',
    plus: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" /></svg>',
    caret: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M7,10L12,15L17,10H7Z" /></svg>',
    delete:
      '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>',
    copy: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" /></svg>',
    arrowUp:
      '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z" /></svg>',
    chevron:
      '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" /></svg>',
    eye: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" /></svg>',
    eyeOff:
      '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z" /></svg>',
  },
  i18n: {},
  undoManager: {},
  assetManager: {},
  canvas: {},
  layerManager: {},
  storageManager: {},
  richTextEditor: {},
  domComponents: {},
  modal: {},
  codeManager: {},
  panels: {},
  commands: {},
  cssComposer: {},
  selectorManager: {},
  deviceManager: {},
  styleManager: {},
  blockManager: {
    blocks: [
      {
        category: 'Basic',
        label: '1 Column',
        media:
          '<svg style="width: 48px; height: 48px" viewBox="0 0 24 24"><path fill="currentColor" d="M2 20h20V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1Z"></path></svg>',
        content:
          '<div style="display: inline-block;width: 100%; height: 200px; color: #fff; text-align: center;line-height: 200px;"></div>',
      },
      {
        category: 'Basic',
        label: '2 Columns',
        media:
          '<svg style="width: 48px; height: 48px" viewBox="0 0 23 24"><path fill="currentColor" d="M2 20h8V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM13 20h8V4h-8v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1Z"></path></svg>',
        content:
          '<table style="width: 100%;"><tr><td style="width: 50%; height: 100px;"><h2></h2><p></p></td><td style="width: 50%; height: 100px;"> <h2></h2><p></p></td></tr></table>',
      },
      {
        category: 'Basic',
        label: '3 Columns',
        media:
          '<svg style="width: 48px; height: 48px" viewBox="0 0 23 24"><path fill="currentColor" d="M2 20h4V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM17 20h4V4h-4v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1ZM9.5 20h4V4h-4v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1Z"></path></svg>',
        content:
          '<div style="width: 100%;"><div style="width: 33.33%; float: left; height: 100px">  <h2></h2><p></p></div><div style="width: 33.33%; float: left; height: 100px">  <h2></h2><p></p></div><div style="width: 33.33%; float: left; height: 100px">  <h2></h2><p></p></div></div>',
      },
      {
        id: 'text',
        label: 'Text',
        category: 'Basic',
        media: svgText,
        activate: true,
        content: {
          type: 'text',
          content: 'Insert your text here',
          style: { padding: '10px' },
        },
      },
      {
        id: 'link',
        label: 'Link',
        category: 'Basic',
        media: svgLink,
        activate: true,
        content: {
          type: 'link',
          content: 'Insert your link here',
          style: { color: '#d983a6' },
        },
      },
      {
        id: 'image',
        label: 'Imagem',
        category: 'Basic',
        media: svgImage,
        activate: true,
        content: { type: 'image' },
      },
      {
        id: 'video',
        label: 'Video',
        category: 'Basic',
        media: svgVideo,
        activate: true,
        content: { type: 'video' },
      },
      {
        id: 'map',
        label: 'Mapa',
        category: 'Basic',
        media:
          '<svg style="width:48px;height:48px" viewBox="0 0 24 24">\n        <path fill="currentColor" d="M20.5,3L20.34,3.03L15,5.1L9,3L3.36,4.9C3.15,4.97 3,5.15 3,5.38V20.5A0.5,0.5 0 0,0 3.5,21L3.66,20.97L9,18.9L15,21L20.64,19.1C20.85,19.03 21,18.85 21,18.62V3.5A0.5,0.5 0 0,0 20.5,3M10,5.47L14,6.87V18.53L10,17.13V5.47M5,6.46L8,5.45V17.15L5,18.31V6.46M19,17.54L16,18.55V6.86L19,5.7V17.54Z" />\n      </svg>',
        content: {
          type: 'map',
          style: {
            height: '350px',
          },
        },
      },
      {
        label: 'Link Block',
        category: 'Basic',
        media:
          '<svg style="width:48px;height:48px" viewBox="0 0 24 24">\n      <path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"></path>\n    </svg>',
        content: {
          type: 'link',
          editable: !1,
          droppable: !0,
          style: {
            display: 'inline-block',
            padding: '5px',
            'min-height': '50px',
            'min-width': '50px',
          },
        },
      },
      {
        label: 'Quote',
        category: 'Basic',
        media:
          '<svg style="width:48px;height:48px" viewBox="0 0 24 24">\n        <path fill="currentColor" d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z" />\n    </svg>',
        content:
          '<blockquote class="quote">\n        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ipsum dolor sit\n      </blockquote>',
      },
      {
        label: 'Text section',
        category: 'Basic',
        media:
          '<svg style="width:48px;height:48px" viewBox="0 0 24 24">\n        <path fill="currentColor" d="M21,6V8H3V6H21M3,18H12V16H3V18M3,13H21V11H3V13Z" />\n    </svg>',
        content:
          '<section class="bdg-sect">\n      <h1 class="heading">Insert title here</h1>\n      <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>\n      </section>',
      },
      {
        label: 'Form',
        category: 'Forms',
        media:
          '<svg style="width: 48px; height: 48px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 5.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 8H3V6h18v2zM22 10.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 13H3v-2h18v2z"/><rect width="10" height="3" x="2" y="15" rx=".5"/></svg>',
        content:
          '<form method="get" id="im5xu"><div><label>Name</label><input type="text"/></div><div><label>Email</label><input type="email"/></div><div><label>Gender</label><input type="checkbox" value="M"/><label>M</label><input type="checkbox" value="F"/><label>F</label></div><div><label>Message</label><textarea></textarea></div><div><button type="button">Send</button></div></form>',
      },
      {
        label: 'Input',
        category: 'Forms',
        media:
          '<svg style="width: 48px; height: 48px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 9c0-.6-.5-1-1.3-1H3.4C2.5 8 2 8.4 2 9v6c0 .6.5 1 1.3 1h17.4c.8 0 1.3-.4 1.3-1V9zm-1 6H3V9h18v6z"/><path d="M4 10h1v4H4z"/></svg>',
        content: '<form method="get" id="im5xu"></form><input type="text" id="ia2ft"/>',
      },
      {
        label: 'Text area',
        category: 'Forms',
        media:
          '<svg style="width: 48px; height: 48px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 7.5c0-.9-.5-1.5-1.3-1.5H3.4C2.5 6 2 6.6 2 7.5v9c0 .9.5 1.5 1.3 1.5h17.4c.8 0 1.3-.6 1.3-1.5v-9zM21 17H3V7h18v10z"/><path d="M4 8h1v4H4zM19 7h1v10h-1zM20 8h1v1h-1zM20 15h1v1h-1z"/></svg>',
        content: '<form method="get" id="im5xu"></form><textarea id="i7a1a"></textarea>',
      },
      {
        category: 'Forms',
        label: 'Select',
        media:
          '<svg style="width: 48px; height: 48px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 9c0-.6-.5-1-1.3-1H3.4C2.5 8 2 8.4 2 9v6c0 .6.5 1 1.3 1h17.4c.8 0 1.3-.4 1.3-1V9zm-1 6H3V9h18v6z"/><path d="M18.5 13l1.5-2h-3zM4 11.5h11v1H4z"/></svg>',
        content:
          '<select type="text" id="igutl"><option value="opt1">Option 1</option><option value="opt2">Option 2</option></select>',
      },
      {
        category: 'Forms',
        label: 'Button',
        media:
          '<svg style="width: 48px; height: 48px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 9c0-.6-.5-1-1.3-1H3.4C2.5 8 2 8.4 2 9v6c0 .6.5 1 1.3 1h17.4c.8 0 1.3-.4 1.3-1V9zm-1 6H3V9h18v6z"/><path d="M4 11.5h16v1H4z"/></svg>',
        content: '<button type="button" id="ilxca">Send</button>',
      },
      {
        category: 'Forms',
        label: 'Label',
        media:
          '<svg style="width: 48px; height: 48px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 11.9c0-.6-.5-.9-1.3-.9H3.4c-.8 0-1.3.3-1.3.9V17c0 .5.5.9 1.3.9h17.4c.8 0 1.3-.4 1.3-.9V12zM21 17H3v-5h18v5z"/><rect width="14" height="5" x="2" y="5" rx=".5"/><path d="M4 13h1v3H4z"/></svg>',
        content: '<label id="ist7m">Label</label>',
      },
      {
        category: 'Forms',
        label: 'Checkbox',
        media:
          '<svg style="width: 48px; height: 48px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 17l-5-5 1.41-1.42L10 14.17l7.59-7.59L19 8m0-5H5c-1.11 0-2 .89-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2z"></path></svg>',
        content: '<input type="checkbox" id="il3zm"/>',
      },
      {
        category: 'Forms',
        label: 'Radio',
        media:
          '<svg style="width: 48px; height: 48px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8m0-18C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 5c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z"></path></svg>',
        content: '<input type="radio" id="i4vbz"/>',
      },
      /* {
  label: 'Input',
  media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 9c0-.6-.5-1-1.3-1H3.4C2.5 8 2 8.4 2 9v6c0 .6.5 1 1.3 1h17.4c.8 0 1.3-.4 1.3-1V9zm-1 6H3V9h18v6z"/><path d="M4 10h1v4H4z"/></svg>',
  content: {
      type: 'input'
  }
} */
    ],
  },
  traitManager: {},
  textViewCode: 'Code',
  keepUnusedStyles: false,
  customUI: false,
};

export default config;
