import Editor from '../../editor';
import Block, { BlockProperties } from '../model/Block';

export interface BlockManagerConfig {
  /**
   * Specify the element to use as a container, string (query) or HTMLElement.
   * With the empty value, nothing will be rendered.
   * @default ''
   */
  appendTo?: HTMLElement | string;
  /**
   * Default blocks.
   * @default []
   */
  blocks?: BlockProperties[];
  /**
   * Append blocks to canvas on click.
   * With the `true` value, it will try to append the block to the selected component
   * If there is no selected component, the block will be appened to the wrapper.
   * You can also pass a function to this option, use it as a catch-all for all block
   * clicks and implement a custom logic for each block.
   * @default false
   * @example
   * // Example with a function
   * appendOnClick: (block, editor) => {
   *  if (block.get('id') === 'some-id')
   *    editor.getSelected().append(block.get('content'))
   *  else
   *    editor.getWrapper().append(block.get('content'))
   * }
   */
  appendOnClick?: boolean | ((block: Block, editor: Editor, opts: { event: Event }) => void);
  /**
   * Avoid rendering the default block manager UI.
   * More about it here: https://grapesjs.com/docs/modules/Blocks.html#customization
   * @default false
   */
  custom?: boolean;
}

const config: BlockManagerConfig = {
  appendTo: '',
  blocks: [
    {
      id: 'image',
      label: 'Image',
      media: `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
      </svg>`,
      // Use `image` component
      content: { type: 'image' },
      // The component `image` is activatable (shows the Asset Manager).
      // We want to activate it once dropped in the canvas.
      activate: true,
      // select: true, // Default with `activate: true`
    },
    {
      id: 'section', // id is mandatory
      label: '<b>Section</b>',
      attributes: { class: 'gjs-block-section' },
      content: `<section>
        <h1>This is a simple title</h1>
        <div>This is just a Lorem text: Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
      </section>`,
    },
    {
      id: 'text',
      label: 'Text',
      content: '<div data-gjs-type="text">Insert your text here</div>',
    },
    {
      id: 'image',
      label: 'Image',
      // Select the component once dropped in canavas
      select: true,
      // You can pass components as a JSON instead of a simple HTML string,
      // in this case we also use a defined component type `image`
      content: { type: 'image' },
      // This triggers `active` on dropped components
      activate: true,
    },
  ],
  appendOnClick: false,
  custom: false,
};

export default config;
